import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { DisplayedUserData, VideoModalData, Data } from "../";

const DataTableCell: React.FC<TableCellProps> = (props) => (
  <TableCell
    {...props}
    sx={{
      borderBottom: "1px solid #ddd",
      padding: "2px 4px",
      fontSize: { xs: "0.6rem", sm: "0.8rem", md: "1rem" },
      ...props.sx,
    }}
  />
);

interface VideoTableProps {
  displayedData: DisplayedUserData;
  sortField: string | undefined;
  sortDirection: "ascending" | "descending" | undefined;
  data: Data[];
  page: number;
  itemsPerPage: number;
  setVideoModal: React.Dispatch<React.SetStateAction<VideoModalData>>;
}

export const VideoTable: React.FC<{ videoTableProps: VideoTableProps }> = ({
  videoTableProps,
}) => {
  const {
    displayedData,
    sortField,
    sortDirection,
    data,
    page,
    itemsPerPage,
    setVideoModal,
  } = videoTableProps;

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: {
          xs: displayedData.isAdmin
            ? "calc(100vh - 269px)"
            : "calc(100vh - 250px)",
          md: "calc(100vh - 60px)",
        },
        overflowY: "scroll",
      }}
    >
      <Table>
        <TableHead
          sx={{
            backgroundColor: "#f5f5f5",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableRow
            sx={{
              backgroundColor: "#d3d3d3",
            }}
          >
            <DataTableCell
              align="center"
              sx={{
                borderRight: "1px solid #ddd",
                fontWeight: "bold",
              }}
            >
              ビデオデータ
            </DataTableCell>
            <DataTableCell
              align="center"
              sx={{
                fontWeight: "bold",
              }}
            >
              ビデオ
            </DataTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.filter((d) => !d.deletedAt).length > 0 ? (
            data
              .sort((a, b) => {
                if (!sortField || !sortDirection) {
                  return 0;
                }
                const field = sortField as keyof Data;
                if (sortDirection === "ascending") {
                  return a[field] > b[field] ? 1 : -1;
                } else {
                  return a[field] < b[field] ? 1 : -1;
                }
              })
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((row: Data, index: number) =>
                row.deletedAt ? null : (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: "#f9f9f9",
                      },
                      "&:hover": {
                        backgroundColor: "#eaeaea",
                      },
                    }}
                  >
                    <DataTableCell
                      sx={{
                        borderRight: "1px solid #ddd",
                        borderBottom: "2px solid #666",
                        verticalAlign: "top",
                      }}
                    >
                      <Table>
                        <TableBody>
                          {[
                            { display: "通話日時", value: "videoCapturedAt" },
                            { display: "お客様名", value: "fvAccountName" },
                            { display: "契約者名", value: "customerName" },
                            {
                              display: "オペレーター名",
                              value: "operatorName",
                            },
                            { display: "ユーザーID", value: "fvAccountId" },
                            { display: "通話ID", value: "callId" },
                          ].map(
                            (
                              field: { display: string; value: string },
                              index: number
                            ) =>
                              displayedData.isAdmin ||
                              field.value !== "fvAccountId" ? (
                                <TableRow key={index}>
                                  <DataTableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      borderRight: "1px solid #ddd",
                                      borderTop:
                                        index === 0 && !displayedData.isAdmin
                                          ? "1px solid #ddd"
                                          : null,
                                    }}
                                  >
                                    <b>{field.display}</b>
                                  </DataTableCell>
                                  <DataTableCell
                                    sx={{
                                      borderTop:
                                        index === 0 && !displayedData.isAdmin
                                          ? "1px solid #ddd"
                                          : null,
                                      overflowX: "auto",
                                    }}
                                  >
                                    {field.value !== "videoCapturedAt"
                                      ? row[field.value as keyof Data]
                                      : `${new Date(
                                          row.videoCapturedAt
                                        ).toLocaleDateString("ja-JP", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })} ${new Date(
                                          row.videoCapturedAt
                                        ).toLocaleTimeString("en-GB")}`}
                                  </DataTableCell>
                                </TableRow>
                              ) : null
                          )}
                        </TableBody>
                      </Table>
                    </DataTableCell>
                    <DataTableCell
                      sx={{
                        maxWidth: { xs: 146, lg: 90 },
                        borderBottom: "2px solid #666",
                      }}
                    >
                      {
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="stretch"
                          sx={{
                            height: "80%",
                            width: "100%",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: "80%",
                            }}
                            alt="video thumbnail"
                            src={row.thumbnailURL}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              mt: 0.5,
                              cursor: "pointer",
                              height: "20%",
                            }}
                            onClick={() =>
                              setVideoModal({
                                modalOpen: true,
                                currentVideoUrl: row.videoURL,
                                currentThumbnailUrl: row.thumbnailURL,
                              })
                            }
                          >
                            ビデオ
                          </Button>
                        </Box>
                      }
                    </DataTableCell>
                  </TableRow>
                )
              )
          ) : (
            <TableRow>
              <DataTableCell>
                <Typography>No video data found</Typography>
              </DataTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VideoTable;
