import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { Box, BoxProps } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const StyledBox: React.FC<BoxProps> = (props) => {
  return (
    <Box
      {...props}
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        ...props.sx,
        padding: "10px 10px",
        backgroundColor: "#ffffff",
        borderRadius: "15px",
        border: "1px solid #d3d3d3",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
      }}
    />
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#91db4d",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          overflowY: "auto",
          width: "100vw",
          height: "100vh",
          zIndex: -1,
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/background-pattern.png"
          })`,
          backgroundRepeat: "repeat",
        }}
      >
        <RouterProvider router={router} />
      </Box>
    </ThemeProvider>
  );
}

export default App;
