import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getVerifyResetPasswordToken,
  postResetPasswordConfirm,
} from "../../../api/user";

interface FormInputs {
  password: string;
  passwordConfirmation: string;
}

export const ResetPasswordConfirmForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
  } = useForm<FormInputs>({ mode: "onChange" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verificationError, setVerificationError] = useState<string | null>(
    "パスワードリセットリンクの有効期限が切れています"
  );
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<string | null>(
    null
  );
  const token = useParams().token || null;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const verifyToken = async () => {
    setLoading(true);
    const { response, error } = await getVerifyResetPasswordToken(token);
    setLoading(false);

    if (!error) {
      response?.isValid
        ? setVerificationError(null)
        : setVerificationError(`Response: ${response || "empty"}`);
    } else if ("status" in error) {
      response && "error" in response
        ? setVerificationError(response.error)
        : setVerificationError(
            `Response: ${response || "empty"}. Error: ${error.status} ${
              error.statusText
            }.`
          );
    } else {
      setVerificationError(`Something went wrong: ${error.text}`);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const onSubmit: SubmitHandler<FormInputs> = async (values) => {
    setIsSubmitting(true);
    setLoading(true);
    const { response, error } = await postResetPasswordConfirm(
      { newPassword: values.password },
      token
    );
    setLoading(false);

    if (!error) {
      if (response && "message" in response) {
        setSubmissionError(null);

        reset();
        localStorage.removeItem("token");
        setSubmissionSuccess("パスワードが正常にリセットしました。");
      } else {
        setSubmissionError(`Response: ${response || "empty"}`);
      }
    } else if ("status" in error) {
      response && "error" in response
        ? setSubmissionError(response.error)
        : setSubmissionError(
            `Response: ${response || "empty"}.
            Error: ${error.status} ${error.statusText}.`
          );
    } else {
      setSubmissionError(`Something went wrong: ${error.text}`);
    }
    setIsSubmitting(false);
  };

  return verificationError ? (
    <Container maxWidth="sm">
      <Typography variant="h5" align="center" sx={{ mb: 3 }}>
        パスワードのリセット
      </Typography>
      <Typography variant="subtitle1" align="center">
        {verificationError}
      </Typography>
    </Container>
  ) : (
    <Container maxWidth="sm">
      {loading ? (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Typography variant="h4" align="center" sx={{ mb: 3 }}>
        パスワードのリセット
      </Typography>
      <Typography variant="subtitle1" align="center">
        新しいパスワードを入力してください。
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          setSubmissionError(null);
        }}
      >
        <TextField
          label="新しいパスワード"
          type="password"
          {...register("password", {
            required: "* 必須",
            minLength: {
              value: 1,
              message: "パスワードを空にすることはできません",
            },
          })}
          error={!!errors.password}
          helperText={errors.password?.message}
          fullWidth
          margin="normal"
          disabled={isSubmitting || !!submissionSuccess}
        />
        <TextField
          label="新しいパスワードを再入力"
          type="password"
          {...register("passwordConfirmation", {
            required: "* 必須",
            minLength: {
              value: 1,
              message: "パスワードを空にすることはできません",
            },
            validate: (value) => {
              return (
                value === getValues("password") || "パスワードが一致しません"
              );
            },
          })}
          error={!!errors.passwordConfirmation}
          helperText={errors.passwordConfirmation?.message}
          fullWidth
          margin="normal"
          disabled={isSubmitting || !!submissionSuccess}
        />
        {submissionError && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {submissionError}
          </Alert>
        )}
        {submissionSuccess && (
          <Alert severity="success" sx={{ mb: 1 }}>
            {submissionSuccess}
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mb: 1 }}
          fullWidth
          disabled={!isValid || isSubmitting}
        >
          送信
        </Button>
        <Button
          onClick={() => navigate("/")}
          variant="contained"
          color="primary"
          fullWidth
        >
          {localStorage.getItem("token")
            ? "メイン画面に戻る"
            : "ログインに戻る"}
        </Button>
      </form>
    </Container>
  );
};

export default ResetPasswordConfirmForm;
