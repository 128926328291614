import { getJson, postJson, deleteJson } from "../utils/network";
import {
  USER_URL,
  LOGIN_URL,
  SIGNUP_URL,
  REQUEST_RESET_PASSWORD_URL,
  VERIFY_RESET_PASSWORD_TOKEN_URL,
  RESET_PASSWORD_URL,
  CHANGE_EMAIL_URL,
  VERIFY_AUTH_TOKEN_URL,
  DELETE_TOKEN_URL,
} from "../constant";

type AuthResponse = { token: string } | { error: string };

export async function postAuth({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return await postJson<AuthResponse>(LOGIN_URL, {
    email,
    password,
  });
}

type SignUpResponse = { token: string } | { error: string };

export async function postSignUp({
  username,
  email,
  password,
  fvAccountId,
}: {
  username: string;
  email: string;
  password: string;
  fvAccountId: string;
}) {
  return await postJson<SignUpResponse>(SIGNUP_URL, {
    username,
    email,
    password,
    C_UserID: fvAccountId,
  });
}

type VerifyAuthTokenResponse =
  | {
      isValid: boolean;
      user:
        | { error: string }
        | {
            username: string;
            email: string;
            fvAccountId: string;
            isAdmin: boolean;
          };
    }
  | { isValid: boolean; error: string };

export async function getAuthVerify(token: string | null) {
  const path = VERIFY_AUTH_TOKEN_URL(token);
  return await getJson<VerifyAuthTokenResponse>(path);
}

type SendResetLinkResponse = { message: string } | { error: string };

export async function postSendResetLink({ email }: { email: string }) {
  return await postJson<SendResetLinkResponse>(REQUEST_RESET_PASSWORD_URL, {
    email,
  });
}

type VerifyResetPasswordTokenResponse =
  | { isValid: boolean; error: string }
  | { isValid: boolean };

export async function getVerifyResetPasswordToken(token: string | null) {
  const path = VERIFY_RESET_PASSWORD_TOKEN_URL(token);
  return await getJson<VerifyResetPasswordTokenResponse>(path);
}

type PasswordResetResponse = { message: string } | { error: string };

export async function postResetPasswordConfirm(
  {
    newPassword,
  }: {
    newPassword: string;
  },
  token: string | null
) {
  return await postJson<PasswordResetResponse>(
    RESET_PASSWORD_URL,
    {
      newPassword,
    },
    token
  );
}

type DeleteUserResponse = { message: string } | { error: string };

export async function deleteUser(
  {
    password,
  }: {
    password: string;
  },
  token: string | null
) {
  return await deleteJson<DeleteUserResponse>(
    USER_URL,
    {
      password,
    },
    token
  );
}

type ChangeEmailResponseData = { message: string } | { error: string };

export async function postChangeEmail(
  {
    newEmail,
    password,
  }: {
    newEmail: string;
    password: string;
  },
  token: string | null
) {
  return await postJson<ChangeEmailResponseData>(
    CHANGE_EMAIL_URL,
    {
      newEmail,
      password,
    },
    token
  );
}

type DeleteTokenResponse = { message: string } | { error: string };

export async function deleteToken(token: string | null) {
  return await deleteJson<DeleteTokenResponse>(
    DELETE_TOKEN_URL,
    undefined,
    token
  );
}
