const defaultOptions: RequestInit = {
  headers: {
    "Content-Type": "application/json",
  },
};

export async function createRequest<T extends object>(
  url: string,
  options?: RequestInit
) {
  const initOptions: RequestInit = {
    mode: "cors",
    ...defaultOptions,
    ...options,
    headers: {
      ...defaultOptions.headers,
      ...options?.headers,
    },
  };

  const res = await fetch(url, initOptions);
  const response: T = await res.json();

  if (res.ok) {
    return { response, error: null };
  } else {
    return {
      response,
      error: { status: res.status, statusText: res.statusText },
    };
  }
}

export async function getJson<T extends object>(
  path: string,
  params?: Record<string, string>,
  token?: string | null
) {
  const query = (params && `?${new URLSearchParams(params).toString()}`) ?? "";

  const options: RequestInit = {
    method: "GET",
  };
  if (token) {
    options.headers = { Authorization: `Bearer ${token}` };
  }
  try {
    return await createRequest<T>(path + query, options);
  } catch (e) {
    return {
      response: null,
      error: { text: e instanceof Error ? e.message : String(e) },
    };
  }
}

export async function postJson<T extends object>(
  path: string,
  body: Record<string, unknown> = {},
  token?: string | null
) {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify(body),
  };
  if (token) {
    options.headers = { Authorization: `Bearer ${token}` };
  }
  try {
    return await createRequest<T>(path, options);
  } catch (e) {
    return {
      response: null,
      error: { text: e instanceof Error ? e.message : String(e) },
    };
  }
}

export async function deleteJson<T extends object>(
  path: string,
  body: Record<string, unknown> = {},
  token?: string | null
) {
  const options: RequestInit = {
    method: "DELETE",
    body: JSON.stringify(body),
  };
  if (token) {
    options.headers = { Authorization: `Bearer ${token}` };
  }
  try {
    return await createRequest<T>(path, options);
  } catch (e) {
    return {
      response: null,
      error: { text: e instanceof Error ? e.message : String(e) },
    };
  }
}
