import React from "react";
import { createBrowserRouter } from "react-router-dom";
import * as routes from "./constants";
import * as firstScreenComponents from "../screens/FirstScreen";
import * as userActionsScreenComponents from "../screens/UserActionsScreen";
import * as resetPasswordScreenComponents from "../screens/ResetPasswordScreen";
import FirstScreen from "../screens/FirstScreen";
import MainScreen from "../screens/MainScreen";
import UserActionsScreen from "../screens/UserActionsScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <FirstScreen />,
    children: [
      {
        path: routes.USER_LOGIN_ROUTE,
        element: <firstScreenComponents.LogInForm />,
      },
      {
        path: routes.USER_SIGNUP_ROUTE,
        element: <firstScreenComponents.SignUpForm />,
      },
    ],
  },
  {
    path: routes.MAIN_SCREEN_ROUTE,
    element: <MainScreen />,
  },
  {
    path: routes.USER_ACTIONS_ROUTE,
    element: <UserActionsScreen />,
    children: [
      {
        path: routes.USER_DELETE_ROUTE,
        element: <userActionsScreenComponents.DeleteUserForm />,
      },
      {
        path: routes.CHANGE_EMAIL_ROUTE,
        element: <userActionsScreenComponents.ChangeEmailForm />,
      },
    ],
  },
  {
    path: routes.USER_PASSWORD_RESET_ROUTE,
    element: <ResetPasswordScreen />,
    children: [
      {
        path: routes.USER_PASSWORD_RESET_REQUEST_ROUTE,
        element: <resetPasswordScreenComponents.ResetPasswordRequestForm />,
      },
      {
        path: routes.USER_PASSWORD_RESET_CONFIRM_ROUTE,
        element: <resetPasswordScreenComponents.ResetPasswordConfirmForm />,
      },
    ],
  },
]);

export default router;
