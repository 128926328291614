const API_BASE_URL = `${process.env.REACT_APP_BACKEND_HOST}${process.env.REACT_APP_API_PATH}`;

export const USER_URL = `${API_BASE_URL}/user`;

export const LOGIN_URL = `${USER_URL}/login`;
export const SIGNUP_URL = `${USER_URL}/signup`;
export const VERIFY_AUTH_TOKEN_URL = (token: string | null) =>
  `${USER_URL}/verify/${token ? token : ""}`;
export const RESET_PASSWORD_URL = `${USER_URL}/reset-password`;
export const REQUEST_RESET_PASSWORD_URL = `${RESET_PASSWORD_URL}/request`;
export const VERIFY_RESET_PASSWORD_TOKEN_URL = (token: string | null) =>
  `${RESET_PASSWORD_URL}/verify/${token ? token : ""}`;
export const CHANGE_EMAIL_URL = `${USER_URL}/change-email`;
export const DELETE_TOKEN_URL = `${USER_URL}/token`;

export const DATA_URL = `${API_BASE_URL}/data`;

export const FETCH_DATA_URL = `${DATA_URL}/get-data`;
