import React from "react";
import { Typography, Box, Pagination, PaginationItem } from "@mui/material";
import { Data } from "../";

interface PageNumbersProps {
  data: Data[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  itemsPerPage: number;
}

export const PageNumbers: React.FC<{
  pageNumberProps: PageNumbersProps;
}> = ({ pageNumberProps }) => {
  const { data, setPage, itemsPerPage } = pageNumberProps;

  return (
    <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
      <Typography
        variant="subtitle2"
        sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}
      >
        1ページあたり{itemsPerPage}個を表示する
      </Typography>
      <Pagination
        count={Math.ceil(data.length / itemsPerPage)}
        onChange={(event, value) => setPage(value)}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}
          />
        )}
        size="small"
      />
    </Box>
  );
};

export default PageNumbers;
