import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Backdrop,
  Dialog,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuthVerify } from "../../api/user";
import { getFetchData } from "../../api/data";
import VideoTable from "./components/VideoTable";
import PageNumbers from "./components/PageNumbers";
import UserPanel from "./components/UserPanel";
import Sorting from "./components/Sorting";

export interface Data {
  fvAccountName: string;
  operatorName: string;
  customerName: string;
  callId: string;
  videoURL: string;
  thumbnailURL: string;
  videoCapturedAt: string;
  fvAccountId: string;
  deletedAt: string;
}

export interface DisplayedUserData {
  username: string;
  email: string;
  fvAccountId: string;
  isAdmin: boolean;
}

export interface VideoModalData {
  modalOpen: boolean;
  currentVideoUrl: string | undefined;
  currentThumbnailUrl: string | undefined;
}

export const MainScreen: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 20;
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<
    "ascending" | "descending" | undefined
  >(undefined);
  const [displayedData, setDisplayedData] = useState<DisplayedUserData>({
    username: "",
    email: "",
    fvAccountId: "",
    isAdmin: false,
  });
  const [videoModal, setVideoModal] = useState<VideoModalData>({
    modalOpen: false,
    currentVideoUrl: undefined,
    currentThumbnailUrl: undefined,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const verifyToken = async (token: string | null) => {
    setLoading(true);
    const { response, error } = await getAuthVerify(token);
    setLoading(false);

    if (!error) {
      if (response?.isValid) {
        return response;
      } else {
        return { isValid: false, error: `Response: ${response || "empty"}` };
      }
    } else if ("status" in error) {
      return response && "error" in response
        ? response
        : {
            isValid: false,
            error: `Response: ${response || "empty"}.
                    Error: ${error.status} ${error.statusText}.`,
          };
    } else {
      return {
        isValid: false,
        error: `Something went wrong: ${error.text}`,
      };
    }
  };

  const fetchData = async (token: string | null) => {
    setLoading(true);
    const { response, error } = await getFetchData(token);
    setLoading(false);

    if (!error && response && !("error" in response)) {
      const data: Data[] = response.map((r) => {
        return {
          fvAccountName: r.fvAccountName,
          operatorName: r.operatorName,
          customerName: r.customerName,
          callId: r.callId,
          videoURL: r.videoURL,
          thumbnailURL: r.thumbnailURL,
          videoCapturedAt: r.videoCapturedAt,
          fvAccountId: r.user?.fvAccountId || "",
          deletedAt: r.deletedAt || "",
        };
      });
      setData(data);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const checkAndFetchData = async () => {
      const verificationResult = await verifyToken(token);
      if (!verificationResult.isValid) {
        localStorage.removeItem("token");
        navigate("/");
      } else if (
        "user" in verificationResult &&
        "username" in verificationResult.user
      ) {
        setDisplayedData({
          username: verificationResult.user.username ?? "",
          email: verificationResult.user.email ?? "",
          fvAccountId: verificationResult.user.fvAccountId ?? "",
          isAdmin: verificationResult.user.isAdmin ?? false,
        });
        fetchData(token);
      } else {
        setDisplayedData({
          username: "",
          email: "",
          fvAccountId: "",
          isAdmin: false,
        });
        fetchData(token);
      }
    };
    checkAndFetchData();
  }, []);

  return (
    <Container maxWidth={false} sx={{ padding: "10px 10px" }}>
      {loading ? (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={9}
          order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}
        >
          <Sorting
            sortingProps={{
              displayedData,
              sortField,
              setSortField,
              sortDirection,
              setSortDirection,
            }}
          />
          <VideoTable
            videoTableProps={{
              displayedData,
              sortField,
              sortDirection,
              data,
              page,
              itemsPerPage,
              setVideoModal,
            }}
          />
          <PageNumbers pageNumberProps={{ data, setPage, itemsPerPage }} />
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          md={0}
          lg={2}
          xl={1}
          order={{ xs: 3, sm: 3, md: 3, lg: 0, xl: 0 }}
        >
          <Box
            component="img"
            alt="Mimamoruno logo"
            src="/logo.103x559.png"
            sx={{
              display: { xs: "none", lg: "block" },
              height: 559,
              width: 103,
              maxHeight: { xs: 559 },
              maxWidth: { xs: 103 },
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={5}
          md={0}
          lg={0}
          xl={0}
          order={{ xs: 0, sm: 0, md: 3, lg: 3, xl: 3 }}
        >
          <Box
            component="img"
            alt="Mimamoruno logo"
            src="/logo.138x121.no_text.png"
            sx={{
              display: { xs: "block", md: "none" },
              height: 121,
              width: 138,
              maxHeight: { xs: 110 },
              maxWidth: { xs: 125 },
            }}
          />
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          md={9}
          lg={0}
          xl={0}
          order={{ xs: 3, sm: 3, md: 0, lg: 3, xl: 3 }}
        >
          <Box
            component="img"
            alt="Mimamoruno logo"
            src="/logo.527x115.png"
            sx={{
              display: { xs: "none", md: "block", lg: "none" },
              height: 115,
              width: 527,
              maxHeight: { xs: 115 },
              maxWidth: { xs: 527 },
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sm={7}
          md={3}
          lg={2}
          xl={2}
          order={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          sx={{ textAlign: "center" }}
        >
          <UserPanel userPanelProps={{ displayedData, setLoading }} />
        </Grid>
      </Grid>
      <Dialog
        open={videoModal.modalOpen}
        onClose={() =>
          setVideoModal({
            modalOpen: false,
            currentVideoUrl: undefined,
            currentThumbnailUrl: undefined,
          })
        }
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Box component="video" controls poster={videoModal.currentThumbnailUrl}>
          <source src={videoModal.currentVideoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 1 }}
          onClick={() =>
            setVideoModal({
              modalOpen: false,
              currentVideoUrl: undefined,
              currentThumbnailUrl: undefined,
            })
          }
        >
          Close
        </Button>
      </Dialog>
    </Container>
  );
};

export default MainScreen;
