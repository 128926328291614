import React, { useEffect } from "react";
import { Container, Box, Backdrop, CircularProgress } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import * as routes from "../../routes/constants";
import { getAuthVerify } from "../../api/user";
import { StyledBox } from "../../App";

export const UserActionsScreen: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const verifyToken = async (token: string | null) => {
    setLoading(true);
    const { response, error } = await getAuthVerify(token);
    setLoading(false);

    if (!error) {
      return response?.isValid
        ? response
        : { isValid: false, error: `Response: ${response || "empty"}` };
    } else if ("status" in error) {
      return response && "error" in response
        ? response
        : {
            isValid: false,
            error: `Response: ${response || "empty"}.
                    Error: ${error.status} ${error.statusText}.`,
          };
    } else {
      return {
        isValid: false,
        error: `Something went wrong: ${error.text}`,
      };
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const verifyCurrentToken = async () => {
      const verificationResult = await verifyToken(token);
      if (!verificationResult.isValid) {
        navigate("/");
      } else {
        if (location.pathname === routes.USER_ACTIONS_ROUTE) {
          navigate(routes.MAIN_SCREEN_ROUTE);
        }
      }
    };
    verifyCurrentToken();
  }, [navigate, location.pathname]);

  return (
    <Container>
      {loading ? (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <StyledBox>
          <Outlet />
        </StyledBox>
      </Box>
    </Container>
  );
};

export default UserActionsScreen;
export * from "./components/DeleteUserForm";
export * from "./components/ChangeEmailForm";
