import React from "react";
import { Container, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { StyledBox } from "../../App";

export const ResetPasswordScreen: React.FC = () => {
  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <StyledBox>
          <Outlet />
        </StyledBox>
      </Box>
    </Container>
  );
};

export default ResetPasswordScreen;
export * from "./components/ResetPasswordRequestForm";
export * from "./components/ResetPasswordConfirmForm";
