import React from "react";
import {
  Typography,
  Box,
  SelectChangeEvent,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import { DisplayedUserData } from "../";

interface SortingProps {
  displayedData: DisplayedUserData;
  sortField: string | undefined;
  setSortField: React.Dispatch<React.SetStateAction<string | undefined>>;
  sortDirection: "ascending" | "descending" | undefined;
  setSortDirection: React.Dispatch<
    React.SetStateAction<"ascending" | "descending" | undefined>
  >;
}

export const Sorting: React.FC<{
  sortingProps: SortingProps;
}> = ({ sortingProps }) => {
  const {
    displayedData,
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
  } = sortingProps;

  const handleChangeSortField = (event: SelectChangeEvent) => {
    setSortField(event.target.value);
  };

  const handleChangeDirection = () => {
    if (sortDirection === "ascending" || !sortDirection) {
      setSortDirection("descending");
    } else {
      setSortDirection("ascending");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body2" component="span">
        ソート:
      </Typography>
      <FormControl variant="outlined">
        <Select
          value={sortField}
          onChange={handleChangeSortField}
          size="small"
          sx={{ fontSize: "0.8rem" }}
        >
          {[
            { display: "通話日時", value: "videoCapturedAt" },
            { display: "お客様名", value: "fvAccountName" },
            { display: "契約者名", value: "customerName" },
            { display: "オペレーター名", value: "operatorName" },
            { display: "通話ID", value: "callId" },
          ].map(
            (
              field: { display: string; value: string | undefined },
              index: number
            ) => (
              <MenuItem
                key={index}
                value={field.value}
                sx={{ fontSize: "0.8rem" }}
              >
                {field.display}
              </MenuItem>
            )
          )}
          {displayedData.isAdmin && (
            <MenuItem value="fvAccountId" sx={{ fontSize: "0.8rem" }}>
              ユーザーID
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleChangeDirection}
      >
        {sortDirection === "ascending" ? (
          <>⬆</>
        ) : sortDirection === "descending" ? (
          <>⬇</>
        ) : (
          <>・</>
        )}
      </Button>
    </Box>
  );
};

export default Sorting;
