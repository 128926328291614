import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { postSendResetLink } from "../../../api/user";

interface FormInputs {
  email: string;
}

export const ResetPasswordRequestForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormInputs>({ mode: "onChange" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormInputs> = async (values) => {
    setIsSubmitting(true);
    setLoading(true);
    const { response, error } = await postSendResetLink(values);
    setLoading(false);

    if (!error) {
      if (response && "message" in response) {
        setSubmissionError(null);
        reset();
        setSubmissionSuccess(
          "パスワードリセットリンクを送信しました。メールを確認してください"
        );
      } else {
        setSubmissionError(`Response: ${response || "empty"}`);
      }
    } else if ("status" in error) {
      response && "error" in response
        ? setSubmissionError(response.error)
        : setSubmissionError(
            `Response: ${response || "empty"}.
            Error: ${error.status} ${error.statusText}.`
          );
    } else {
      setSubmissionError(`Something went wrong: ${error.text}`);
    }
    setIsSubmitting(false);
  };

  return (
    <Container maxWidth="sm">
      {loading ? (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Typography variant="h5" align="center" sx={{ mb: 3 }}>
        パスワードを忘れた方
      </Typography>
      <Typography variant="subtitle1" align="center">
        入力したメールアドレスに、パスワードリセット用のリンクを送信します。
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          setSubmissionSuccess(null);
          setSubmissionError(null);
        }}
      >
        <TextField
          label="メールアドレス"
          {...register("email", {
            required: "* 必須",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "無効なメールアドレス",
            },
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
          fullWidth
          margin="normal"
          disabled={isSubmitting}
        />
        {submissionError && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {submissionError}
          </Alert>
        )}
        {submissionSuccess && (
          <Alert severity="success" sx={{ mb: 1 }}>
            {submissionSuccess}
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mb: 1 }}
          fullWidth
          disabled={!isValid || isSubmitting}
        >
          送信
        </Button>
      </form>
      <Button
        onClick={() => navigate("/")}
        variant="contained"
        color="primary"
        fullWidth
      >
        {localStorage.getItem("token") ? "メイン画面に戻る" : "ログインに戻る"}
      </Button>
    </Container>
  );
};

export default ResetPasswordRequestForm;
