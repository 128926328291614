import { getJson } from "../utils/network";
import { FETCH_DATA_URL } from "../constant";

type FetchDataResponse =
  | {
      fvAccountName: string;
      operatorName: string;
      customerName: string;
      callId: string;
      videoURL: string;
      thumbnailURL: string;
      videoCapturedAt: string;
      user?: { fvAccountId: string };
      deletedAt: string | null;
    }[]
  | { error: string };

export async function getFetchData(token: string | null) {
  return await getJson<FetchDataResponse>(FETCH_DATA_URL, undefined, token);
}
