import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as routes from "../../../routes/constants";
import { postSignUp } from "../../../api/user";

interface FormInputs {
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  fvAccountId: string;
}

export const SignUpForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
  } = useForm<FormInputs>({ mode: "onChange" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormInputs> = async ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    passwordConfirmation,
    ...signUpData
  }) => {
    setIsSubmitting(true);
    setLoading(true);
    const { response, error } = await postSignUp(signUpData);
    setLoading(false);

    if (!error) {
      if (response && "token" in response) {
        setSubmissionError(null);
        localStorage.setItem("token", response.token);
        reset();
        setSubmissionSuccess(
          "アカウントが正常に作成しました。まもなくログインされます"
        );
        setTimeout(() => {
          navigate(routes.MAIN_SCREEN_ROUTE);
        }, 3000);
      } else {
        setSubmissionError(`Response: ${response || "empty"}`);
      }
    } else if ("status" in error) {
      response && "error" in response
        ? setSubmissionError(response.error)
        : setSubmissionError(
            `Response: ${response || "empty"}.
            Error: ${error.status} ${error.statusText}.`
          );
    } else {
      setSubmissionError(`Something went wrong: ${error.text}`);
    }
    setIsSubmitting(false);
  };

  return (
    <Container disableGutters maxWidth="sm">
      {loading ? (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Typography variant="h5" align="center">
        新規登録
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          setSubmissionError(null);
        }}
      >
        <TextField
          label="名前"
          {...register("username", {
            required: "* 必須",
            minLength: {
              value: 1,
              message: "名前を空にすることはできません",
            },
          })}
          error={!!errors.username}
          helperText={errors.username?.message}
          fullWidth
          margin="normal"
          disabled={isSubmitting}
        />
        <TextField
          label="メールアドレス"
          {...register("email", {
            required: "* 必須",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "無効なメールアドレス",
            },
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
          fullWidth
          margin="normal"
          disabled={isSubmitting}
        />
        <TextField
          label="FVユーザーID"
          {...register("fvAccountId", {
            required: "* 必須",
            minLength: {
              value: 1,
              message: "FVユーザーIDを空にすることはできません",
            },
          })}
          error={!!errors.fvAccountId}
          helperText={errors.fvAccountId?.message}
          fullWidth
          margin="normal"
          disabled={isSubmitting}
        />
        <TextField
          label="パスワード"
          type="password"
          {...register("password", {
            required: "* 必須",
            minLength: {
              value: 1,
              message: "パスワードを空にすることはできません",
            },
          })}
          error={!!errors.password}
          helperText={errors.password?.message}
          fullWidth
          margin="normal"
          disabled={isSubmitting}
          sx={{ mt: 3 }}
        />
        <TextField
          label="パスワードを再入力"
          type="password"
          {...register("passwordConfirmation", {
            required: "* 必須",
            minLength: {
              value: 1,
              message: "パスワードを空にすることはできません",
            },
            validate: (value) => {
              return (
                value === getValues("password") || "パスワードが一致しません"
              );
            },
          })}
          error={!!errors.passwordConfirmation}
          helperText={errors.passwordConfirmation?.message}
          fullWidth
          margin="normal"
          disabled={isSubmitting || !!submissionSuccess}
        />
        {submissionError && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {submissionError}
          </Alert>
        )}
        {submissionSuccess && (
          <Alert severity="success" sx={{ mb: 1 }}>
            {submissionSuccess}
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!isValid || isSubmitting}
        >
          送信
        </Button>
      </form>
    </Container>
  );
};

export default SignUpForm;
