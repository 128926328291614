import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { DisplayedUserData } from "../";
import * as routes from "../../../routes/constants";
import { deleteToken } from "../../../api/user";
import { useNavigate } from "react-router-dom";

const UserTableCell: React.FC<TableCellProps> = (props) => (
  <TableCell
    {...props}
    align="center"
    sx={{
      ...props.sx,
      padding: "2px 4px",
      fontSize: { xs: "0.6rem", md: "0.7rem" },
    }}
  />
);

interface UserPanelProps {
  displayedData: DisplayedUserData;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserPanel: React.FC<{
  userPanelProps: UserPanelProps;
}> = ({ userPanelProps }) => {
  const { displayedData, setLoading } = userPanelProps;

  const [actionsAnchorElement, setActionsAnchorElement] =
    useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleResetPassword = () =>
    navigate(
      [
        routes.USER_PASSWORD_RESET_ROUTE,
        routes.USER_PASSWORD_RESET_REQUEST_ROUTE,
      ].join("/")
    );

  const handleChangeEmail = () =>
    navigate([routes.USER_ACTIONS_ROUTE, routes.CHANGE_EMAIL_ROUTE].join("/"));

  const handleDeleteUser = () =>
    navigate([routes.USER_ACTIONS_ROUTE, routes.USER_DELETE_ROUTE].join("/"));

  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    const { response, error } = await deleteToken(token);
    setLoading(false);

    if (!error) {
      if (response && "message" in response) {
        localStorage.removeItem("token");
        navigate("/");
        return response;
      } else {
        return {
          message: `Response: ${response || "empty"}`,
        };
      }
    } else if ("status" in error) {
      return response && "error" in response
        ? response
        : {
            error: `Response: ${response || "empty"}.
                Error: ${error.status} ${error.statusText}.`,
          };
    } else {
      return { error: `Something went wrong: ${error.text}` };
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {[
              { display: "名前", value: "username" },
              { display: "メールアドレス", value: "email" },
              { display: "FVユーザーID", value: "fvAccountId" },
            ].map(
              (field: { display: string; value: string }, index: number) => (
                <TableRow key={index}>
                  <UserTableCell sx={{ whiteSpace: "nowrap" }}>
                    {field.display}
                  </UserTableCell>
                  <UserTableCell
                    sx={{
                      overflowX: "auto",
                    }}
                  >
                    {displayedData[field.value as keyof DisplayedUserData]}
                  </UserTableCell>
                </TableRow>
              )
            )}
            {displayedData.isAdmin && (
              <TableRow>
                <UserTableCell>Admin account</UserTableCell>
                <UserTableCell>TRUE</UserTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="primary"
        onClick={(event) => setActionsAnchorElement(event.currentTarget)}
        sx={{ mt: 1 }}
      >
        メニュー
      </Button>
      <Menu
        anchorEl={actionsAnchorElement}
        open={!!actionsAnchorElement}
        onClose={() => setActionsAnchorElement(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "15px",
          },
        }}
      >
        {[
          { display: "パスワードリセット", action: handleResetPassword },
          { display: "メールアドレスの変更", action: handleChangeEmail },
          { display: "ユーザーの削除", action: handleDeleteUser },
          { display: "ログアウト", action: handleLogout },
        ].map(
          (
            field: {
              display: string;
              action: React.MouseEventHandler<HTMLLIElement>;
            },
            index: number
          ) => (
            <MenuItem
              key={index}
              onClick={field.action}
              sx={{ borderBottom: "1px solid #ddd" }}
            >
              {field.display}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
};

export default UserPanel;
