import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as routes from "../../../routes/constants";
import { postChangeEmail } from "../../../api/user";

interface PasswordFormInputs {
  password: string;
}

interface EmailFormInputs {
  newEmail: string;
}

export const ChangeEmailForm: React.FC = () => {
  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: passwordErrors, isValid: isPasswordValid },
    reset: resetPassword,
  } = useForm<PasswordFormInputs>({ mode: "onChange" });
  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: emailErrors, isValid: isEmailValid },
    reset: resetEmail,
  } = useForm<EmailFormInputs>({ mode: "onChange" });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<string | null>(
    null
  );
  const [submittedPassword, setSubmittedPassword] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmitPassword: SubmitHandler<PasswordFormInputs> = (values) =>
    setSubmittedPassword(values.password);

  const onSubmitEmail: SubmitHandler<EmailFormInputs> = async (values) => {
    setIsSubmitting(true);
    const token = localStorage.getItem("token");
    if (submittedPassword) {
      setLoading(true);
      const { response, error } = await postChangeEmail(
        { newEmail: values.newEmail, password: submittedPassword },
        token
      );
      setLoading(false);

      if (!error) {
        if (response && "message" in response) {
          resetPassword();
          resetEmail();
          setSubmissionSuccess("メールアドレスが正常に変更しました。");
        } else {
          setSubmissionError(`Response: ${response || "empty"}`);
        }
      } else if ("status" in error) {
        response && "error" in response
          ? setSubmissionError(response.error)
          : setSubmissionError(
              `Response: ${response || "empty"}.
              Error: ${error.status} ${error.statusText}.`
            );
      } else {
        setSubmissionError(`Something went wrong: ${error.text}`);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Container maxWidth="sm">
      {loading ? (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Typography variant="h5" align="center" sx={{ mb: 3 }}>
        メールアドレスの変更
      </Typography>
      <Typography variant="subtitle1" align="center">
        {submittedPassword
          ? "新しいメールアドレスを入力してください。"
          : "パスワードを入力してください。"}
      </Typography>
      {submittedPassword ? (
        <form
          onSubmit={handleSubmitEmail(onSubmitEmail)}
          onChange={() => {
            setSubmissionError(null);
          }}
        >
          <TextField
            label="新しいメールアドレス"
            {...registerEmail("newEmail", {
              required: "* 必須",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "無効なメールアドレス",
              },
            })}
            error={!!emailErrors.newEmail}
            helperText={emailErrors.newEmail?.message}
            fullWidth
            margin="normal"
            disabled={isSubmitting || !!submissionSuccess}
            key="newEmail"
          />
          {submissionError && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {submissionError}
            </Alert>
          )}
          {submissionSuccess && (
            <Alert severity="success" sx={{ mb: 1 }}>
              {submissionSuccess}
            </Alert>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mb: 1 }}
            fullWidth
            disabled={!isEmailValid || isSubmitting}
          >
            送信
          </Button>
        </form>
      ) : (
        <form
          onSubmit={handleSubmitPassword(onSubmitPassword)}
          onChange={() => {
            setSubmissionError(null);
          }}
        >
          <TextField
            label="パスワード"
            type="password"
            {...registerPassword("password", {
              required: "* 必須",
              minLength: {
                value: 1,
                message: "パスワードを空にすることはできません",
              },
            })}
            error={!!passwordErrors.password}
            helperText={passwordErrors.password?.message}
            fullWidth
            margin="normal"
            disabled={isSubmitting}
            key="password"
          />
          {submissionError && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {submissionError}
            </Alert>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mb: 1 }}
            fullWidth
            disabled={!isPasswordValid || isSubmitting}
          >
            送信
          </Button>
        </form>
      )}
      {submittedPassword ? (
        <Button
          onClick={() => setSubmittedPassword(null)}
          variant="contained"
          color="primary"
          sx={{ mb: 1 }}
          fullWidth
          disabled={!!submissionSuccess}
        >
          パスワードを入力に戻る
        </Button>
      ) : null}
      <Button
        onClick={() => navigate(routes.MAIN_SCREEN_ROUTE)}
        variant="contained"
        color="primary"
        fullWidth
      >
        メイン画面に戻る
      </Button>
    </Container>
  );
};

export default ChangeEmailForm;
