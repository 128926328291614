import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../../../api/user";

interface FormInputs {
  password: string;
}

export const DeleteUserForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormInputs>({ mode: "onChange" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormInputs> = async (values) => {
    setIsSubmitting(true);
    console.log(values);
    const token = localStorage.getItem("token");
    setLoading(true);
    const { response, error } = await deleteUser(values, token);
    setLoading(false);

    if (!error) {
      if (response && "message" in response) {
        setSubmissionError(null);
        console.log(response.message);
        reset();
        localStorage.removeItem("token");
        setSubmissionSuccess("ユーザーが正常に削除しました。");
      } else {
        setSubmissionError(`Response: ${response || "empty"}`);
      }
    } else if ("status" in error) {
      response && "error" in response
        ? setSubmissionError(response.error)
        : setSubmissionError(
            `Response: ${response || "empty"}.
            Error: ${error.status} ${error.statusText}.`
          );
    } else {
      setSubmissionError(`Something went wrong: ${error.text}`);
    }
    setIsSubmitting(false);
  };

  return (
    <Container maxWidth="sm">
      {loading ? (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Typography variant="h5" align="center" sx={{ mb: 3 }}>
        ユーザーの削除
      </Typography>
      <Typography variant="subtitle1" align="center">
        ユーザーを削除しますか？削除する場合はパスワードを入力し、削除ボタンを押してください。
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          setSubmissionError(null);
        }}
      >
        <TextField
          label="パスワード"
          type="password"
          {...register("password", {
            required: "* 必須",
            minLength: {
              value: 1,
              message: "パスワードを空にすることはできません",
            },
          })}
          error={!!errors.password}
          helperText={errors.password?.message}
          fullWidth
          margin="normal"
          disabled={isSubmitting || !!submissionSuccess}
        />
        {submissionError && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {submissionError}
          </Alert>
        )}
        {submissionSuccess && (
          <Alert severity="success" sx={{ mb: 1 }}>
            {submissionSuccess}
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="error"
          sx={{ mb: 1 }}
          fullWidth
          disabled={!isValid || isSubmitting}
        >
          削除
        </Button>
      </form>
      <Button
        onClick={() => {
          navigate("/");
        }}
        variant="contained"
        color="primary"
        fullWidth
      >
        {localStorage.getItem("token") ? "メイン画面に戻る" : "ログインに戻る"}
      </Button>
    </Container>
  );
};

export default DeleteUserForm;
