import React, { useEffect } from "react";
import { Container, Typography, Button, Box, Divider } from "@mui/material";
import { Outlet, useNavigate, useLocation, Link } from "react-router-dom";
import * as routes from "../../routes/constants";
import { StyledBox } from "../../App";
import { getAuthVerify } from "../../api/user";

export const FirstScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const verifyToken = async (token: string | null) => {
    const { response, error } = await getAuthVerify(token);

    if (!error) {
      return response?.isValid
        ? response
        : { isValid: false, error: `Response: ${response || "empty"}` };
    } else if ("status" in error) {
      return response && "error" in response
        ? response
        : {
            isValid: false,
            error: `Response: ${response || "empty"}.
                    Error: ${error.status} ${error.statusText}.`,
          };
    } else {
      return {
        isValid: false,
        error: `Something went wrong: ${error.text}`,
      };
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const verifyAndNavigate = async () => {
      const verificationResult = await verifyToken(token);

      if (verificationResult.isValid) {
        navigate(routes.MAIN_SCREEN_ROUTE);
      } else {
        localStorage.removeItem("token");
        navigate(routes.USER_LOGIN_ROUTE);
      }
    };

    if (token) {
      verifyAndNavigate();
    } else if (location.pathname === "/") {
      navigate(routes.USER_LOGIN_ROUTE);
    }
  }, [navigate, location.pathname]);

  const isSignUpRoute = location.pathname === routes.USER_SIGNUP_ROUTE;

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography
          align="center"
          variant="h3"
          sx={{ mb: 3, fontFamily: "Raleway" }}
        >
          みまもるーの
        </Typography>
        <StyledBox>
          <Outlet />
          <Box display="flex" alignItems="center" width="100%" sx={{ my: 2 }}>
            <Box flexGrow={1}>
              <Divider />
            </Box>
            <Typography variant="h5" sx={{ mx: 2 }}>
              or
            </Typography>
            <Box flexGrow={1}>
              <Divider />
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ mb: 3 }}
            onClick={() =>
              navigate(
                isSignUpRoute
                  ? routes.USER_LOGIN_ROUTE
                  : routes.USER_SIGNUP_ROUTE
              )
            }
            fullWidth
          >
            {isSignUpRoute ? "ログインに戻る" : "新規登録"}
          </Button>
          {!isSignUpRoute && (
            <Link
              to={[
                routes.USER_PASSWORD_RESET_ROUTE,
                routes.USER_PASSWORD_RESET_REQUEST_ROUTE,
              ].join("/")}
            >
              パスワードを忘れた方
            </Link>
          )}
        </StyledBox>
      </Box>
    </Container>
  );
};

export default FirstScreen;
export * from "./components/LogInForm";
export * from "./components/SignUpForm";
